const siteConfig = {
  appUrl: 'https://digital-programs-dashboard.prod.sidearmdev.com',
  apiUrl: 'https://digital-programs-api.prod.sidearmdev.com/api',
  sidearmApiUrl: 'https://api.sidearmdev.com/',
  apiKey: 'I6AaB1vFQq7Ix1PLthGLD5L6Bs0fw3Eg2n6g75LV',
  frontendUrl: 'https://digitalprograms.learfield.com',
  cognito: {
    redirectURI: '/login/oauth2/code/cognito',
    redirectURISignout: '/logout',
    userPoolId: 'test-cognito',
    appDomain: 'victor-test.auth.us-west-2.amazoncognito.com',
    clientId: '48uq6gpe7s51kjri3pshs58gja',
  },
  aws: {
    digitalPrograms: {
      accessKey: 'AKIAUV7NRTIHHV3WV4XH',
      secretAccessKey: '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',
      region: 'us-east-2',
      bucket: 'dig-prog-public-res',
    },
    legacy: {
      accessKey: 'AKIAIQFHEJGPEROV5LRA',
      secretAccessKey: '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',
      region: 'us-east-1',
      bucket: '',
    },
    nextgen: {
      accessKey: 'AKIAUV7NRTIHA6UL2JQP',
      secretAccessKey: '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',
      region: 'us-east-2',
      bucket: 'sidearm.nextgen.sites',
    },
    sqs: {
      accessKey: '',
      secretAccessKey: '',
      region: 'us-east-2',
      queueName: 'lfdp_pub_prod_queue',
    },
    cognito: {
      redirectURI: '/login/oauth2/code/cognito',
      redirectURISignout: '/logout',
      userPoolId: 'test-cognito',
      appDomain: 'victor-test.auth.us-west-2.amazoncognito.com',
      clientId: '48uq6gpe7s51kjri3pshs58gja',
    },
  },
};

export default siteConfig;
