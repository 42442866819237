<!-- eslint-disable vue/valid-v-slot -->
<template>
  <div>
    <v-card-text class="scrollable">
      <label class="text--secondary" for="sport">Sport</label>
      <v-select
        id="sport"
        class="mt-2 mb-4"
        hide-details
        outlined
        dense
        v-model="sportId"
        :items="sports"
        item-value="clientSportId"
        item-text="name"
        :disabled="isFormDisabled"
      ></v-select>
      <label class="text--secondary" for="search">Search</label>
      <v-text-field
        class="mt-2 mb-4"
        dense
        outlined
        hide-details
        placeholder="Keyword in story title or body"
        v-model="search"
        :disabled="isFormDisabled"
      ></v-text-field>
      <label class="text--secondary">Select Story</label>
      <v-data-table
        class="elevation-2 mt-2 custom-table"
        :class="{ invalid: !isFormValid }"
        v-model="selected"
        :loading="loading"
        :items="stories"
        :headers="headers"
        :server-items-length="total"
        show-select
        single-select
        :options.sync="options"
        disable-sort
        disable-filtering
      >
        <template #item.data-table-select="{ item, select }">
          <v-checkbox
            class="mt-0"
            hide-details
            :value="item.id === storyId"
            on-icon="$radioOn"
            off-icon="$radioOff"
            @change="select"
            :disabled="isFormDisabled"
          ></v-checkbox>
        </template>
        <template #item.postDate="{ value }">
          <span v-format-date:[dateFormat]="value"></span>
        </template>
      </v-data-table>
    </v-card-text>
    <v-card-actions class="pa-4 elevation-5">
      <v-btn depressed color="grey lighten-4" class="text-capitalize" @click="goBack" :disabled="isFormDisabled">
        Back
      </v-btn>
      <v-btn
        type="submit"
        color="primary"
        depressed
        class="text-capitalize"
        :disabled="!isFormValid || isFormDisabled"
        @click="onSubmit"
      >
        Continue
      </v-btn>
      <span v-if="!isFormValid" class="error--text pl-3">Story is required</span>
      <v-spacer></v-spacer>
      <v-btn depressed color="grey lighten-4" class="text-capitalize" @click="reset" :disabled="isFormDisabled">
        Clear
      </v-btn>
      <v-btn text class="text-capitalize elevation-0" @click="close">Cancel</v-btn>
    </v-card-actions>
  </div>
</template>
<script lang="ts">
  import Vue, { VueConstructor } from 'vue';
  import ClientService from '@/services/client/client';
  import { mapActions, mapGetters } from 'vuex';
  const allOption = {
    clientSportId: null as number,
    name: 'Show All',
  };
  interface Injections {
    setPageTitle: (title: string) => void;
    setPageContent: (content: any) => void;
    toggleLoading: () => void;
    goNext: () => void;
    goBack: () => void;
    close: () => void;
  }
  export default (Vue as VueConstructor<Vue & Injections>).extend({
    name: 'StoryFinder',
    inject: ['setPageTitle', 'setPageContent', 'toggleLoading', 'goNext', 'goBack', 'close'],
    data() {
      return {
        dateFormat: 'M/d/yy',
        sportId: null,
        sports: [allOption],
        search: '',
        timeout: null,
        stories: [],
        total: 0,
        options: {
          page: 1,
          itemsPerPage: 5,
        },
        loading: false,
        headers: [
          {
            text: 'ID',
            align: 'start',
            value: 'id',
          },
          {
            text: 'Post Date',
            align: 'start',
            value: 'postDate',
          },
          {
            text: 'Sport',
            align: 'start',
            value: 'sports',
          },
          {
            text: 'Headline',
            align: 'start',
            value: 'title',
          },
        ],
        selected: [],
        isFormValid: true,
        isFormDisabled: false,
      };
    },
    computed: {
      ...mapGetters('programs', { program: 'getProgram' }),
      storyId(): string | null {
        const story = this.selected[0];
        if (!story) return null;
        return story.id;
      },
    },
    methods: {
      ...mapActions('ui', { showToast: 'showToast' }),
      fetchClient: ClientService.fetchClient,
      fetchSports: ClientService.fetchSports,
      fetchStories: ClientService.fetchStories,
      fetchStory: ClientService.fetchStory,
      async refresh() {
        this.loading = true;
        const {
          program,
          sportId,
          search,
          options: { page, itemsPerPage: perPage },
        } = this;
        const { clientId } = program;
        const { items, total } = await this.fetchStories({ clientId, page, perPage, sportId, search });
        this.stories = items;
        this.total = total;
        this.selected = [];
        this.loading = false;
      },
      reset() {
        this.selected = [];
        this.search = '';
        this.sportId = null;
      },
      async importStory() {
        const { storyId, program } = this;
        const { clientId } = program;
        const [client, story] = await Promise.all([this.fetchClient(clientId), this.fetchStory({ clientId, storyId })]);

        let imageUrl = '',
          videoUrl = '';
        const httpRegex = /(http|https)/;

        if (story.videoImageUrl) {
          imageUrl = httpRegex.test(story.videoImageUrl) ? story.videoImageUrl : client.siteUrl + story.videoImageUrl;
        } else if (story.image) {
          imageUrl = client.siteUrl + story.image;
        }
        if (story.videoYouTubeUrl) {
          videoUrl = story.videoYouTubeUrl;
        } else if (story.videoMediaId) {
          videoUrl = `${client.siteUrl}/showcase/embed.aspx?Archive=${story.videoMediaId}&controls=true`;
        }

        const headerText = 'Featured Story';
        this.setPageTitle(`${headerText} Import`);

        const headerBlock = {
          id: 1,
          data: {
            text: headerText,
          },
          type: 'header',
          config: {
            label: 'Describe what this page is about',
            title: 'Page Header',
            placeholder: 'The page header goes here',
          },
        };
        const mediaBlock = {
          id: 2,
          data: {
            alt: story.imageAltText || '',
            link: '',
            image: imageUrl,
            caption: story.imageCaption || '',
            hasVideo: !!story.videoYouTubeUrl || !!story.videoMediaId,
            videoUrl,
            hasCaption: !!story.imageCaption,
          },
          type: 'media',
          config: {
            type: 'image-video',
            title: 'Story Image',
          },
        };
        const headlineBlock = {
          id: 3,
          data: {
            text: story.title,
          },
          type: 'headline',
          config: {
            label: 'Headline',
            title: 'Story Headline Text',
            placeholder: 'The story headline goes here',
          },
        };
        const bodyBlock = {
          id: 4,
          data: {
            html: story.body.replace(/\r?\n|\r/g, ''),
          },
          type: 'html',
          config: {
            label: 'Body',
            title: 'Story Body Text',
          },
        };
        return {
          blocks: [headerBlock, mediaBlock, headlineBlock, bodyBlock],
        };
      },
      async onSubmit() {
        this.isFormValid = !!this.storyId;
        if (!this.isFormValid) return;
        try {
          this.toggleLoading();
          this.isFormDisabled = true;
          const content = await this.importStory();
          this.setPageContent(content);
          this.goNext();
        } catch (error) {
          const msg = error?.response?.data || 'Could not import page content';
          this.showToast(msg);
          // console.warn(error);
        } finally {
          this.toggleLoading();
          this.isFormDisabled = false;
        }
      },
    },
    async created() {
      const { program } = this;
      const { clientId } = program;
      const sports = await this.fetchSports({ clientId });
      this.sports = [allOption, ...sports];
    },
    watch: {
      async sportId(sportId: number | null) {
        if (sportId !== undefined) await this.refresh();
      },
      async options(options: any) {
        if (options) await this.refresh();
      },
      search(text: string) {
        if (this.timeout) clearTimeout(this.timeout);
        if (typeof text === 'string' && (text.length < 1 || text.length > 3)) {
          this.timeout = setTimeout(() => {
            this.refresh();
          }, 500);
        }
      },
      storyId(id: string) {
        if (id) this.isFormValid = true;
      },
    },
  });
</script>
