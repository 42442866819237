<template>
  <div>
    <v-card-text class="scrollable">
      <label class="text--secondary">Select schedule to import</label>
      <v-tabs v-model="tab" grow class="px-4">
        <v-tabs-slider color="primary"></v-tabs-slider>
        <v-tab v-for="item in items" :key="item">
          {{ item }}
        </v-tab>
      </v-tabs>
      <v-tabs-items v-model="tab" class="px-4">
        <v-tab-item v-for="item in items" :key="item">
          <v-card flat>
            <div v-if="item === 'Results'" class="pt-0 my-4 d-flex flex-column gap-4">
              <v-data-table
                class="elevation-2 mt-2 custom-table"
                :class="{ invalid: !isFormValid }"
                checkbox-color="primary"
                :headers="headersResult"
                disable-pagination
                hide-default-footer
                show-select
                fixed-header
                sort-by="firstName"
                item-key="date"
                :items="scheduleResults"
                v-model="selectedResults"
                height="360"
                :loading="isLoading"
                disable-sort
                disable-filtering
              >
                <template #[`item.date`]="props">
                  <span v-format-date:[dateFormat]="props.item.date">{{ props.item.date }}</span>
                </template>
                <template #[`item.time`]="props">
                  <span v-format-date:[timeFormat]="props.item.date"></span>
                </template>
                <template #[`item.result`]="props">
                  <span>
                    {{ props.item.result?.status }} {{ props.item.result?.teamScore }}-{{
                      props.item.result?.opponentScore
                    }}
                  </span>
                </template>
                <template #[`item.locationIndicator`]="props">
                  <span v-if="props.item.locationIndicator">{{
                    props.item.locationIndicator === 'H' ? 'Vs' : 'At'
                  }}</span>
                </template>
              </v-data-table>
            </div>
            <div v-if="item === 'Upcoming Events'" class="pt-0 my-4 d-flex flex-column gap-4">
              <v-data-table
                class="elevation-2 mt-2 custom-table"
                :class="{ invalid: !isFormValid }"
                checkbox-color="primary"
                :headers="headersUpcoming"
                disable-pagination
                hide-default-footer
                show-select
                fixed-header
                sort-by="firstName"
                item-key="date"
                :items="scheduleUpcoming"
                v-model="selectedUpcoming"
                height="360"
                :loading="isLoading"
                disable-sort
                disable-filtering
              >
                <template #[`item.date`]="props">
                  <span v-format-date:[dateFormat]="props.item.date">{{ props.item.date }}</span>
                </template>
                <template #[`item.locationIndicator`]="props">
                  <span v-if="props.item.locationIndicator">{{
                    props.item.locationIndicator === 'H' ? 'Vs' : 'At'
                  }}</span>
                </template>
              </v-data-table>
            </div>
          </v-card>
        </v-tab-item>
      </v-tabs-items>
    </v-card-text>
    <v-card-actions class="pa-4 elevation-5">
      <v-btn depressed color="grey lighten-4" class="text-capitalize" @click="goBack" :disabled="isFormDisabled">
        Back
      </v-btn>
      <v-btn
        type="submit"
        color="primary"
        depressed
        class="text-capitalize"
        :disabled="!isFormValid || isFormDisabled"
        @click="onSubmit"
      >
        Continue
      </v-btn>
      <span v-if="!isFormValid" class="error--text">Select a least one event</span>
      <v-spacer></v-spacer>
      <v-btn depressed color="grey lighten-4" class="text-capitalize" @click="reset" :disabled="isFormDisabled">
        Clear
      </v-btn>
      <v-btn text class="text-capitalize elevation-0" @click="close">Cancel</v-btn>
    </v-card-actions>
  </div>
</template>
<script lang="ts">
  import Vue, { VueConstructor } from 'vue';
  import ClientService from '@/services/client/client';
  import { mapActions, mapGetters } from 'vuex';
  import { ScheduleEventsGetters } from '@/store/client/types';
  import { DateTime } from 'luxon';

  interface Injections {
    setPageTitle: (title: string) => void;
    setPageContent: (content: any) => void;
    toggleLoading: () => void;
    getPageSport: () => any;
    getPageEvent: () => any;
    goNext: () => void;
    goBack: () => void;
    close: () => void;
  }
  export default (Vue as VueConstructor<Vue & Injections>).extend({
    name: 'OpponentScheduleImporter',
    inject: [
      'setPageTitle',
      'setPageContent',
      'toggleLoading',
      'getPageSport',
      'getPageEvent',
      'goNext',
      'goBack',
      'close',
    ],
    data() {
      return {
        opponentTenantId: '',
        opponentSiteUrl: '',
        globalSportId: 0,
        tab: 0 as number,
        items: ['Results', 'Upcoming Events'] as string[],
        dateFormat: 'M/d/yy',
        timeFormat: 'h:mma',
        scheduleResults: [] as ScheduleEventsGetters[],
        selectedResults: [] as ScheduleEventsGetters[],
        scheduleUpcoming: [] as ScheduleEventsGetters[],
        selectedUpcoming: [] as ScheduleEventsGetters[],
        headersResult: [
          {
            text: 'Opponent Name',
            align: 'start',
            value: 'opponent.title',
            width: 200,
          },
          {
            text: 'Location',
            align: 'start',
            value: 'location',
            width: 150,
          },
          {
            text: 'Stadium Name',
            align: 'start',
            value: 'stadiumName',
            width: 150,
          },
          {
            text: 'Indicator',
            align: 'start',
            value: 'locationIndicator',
            width: 80,
          },
          {
            text: 'Date',
            align: 'start',
            value: 'date',
            width: 80,
          },
          {
            text: 'Time',
            align: 'start',
            value: 'time',
            width: 80,
          },
          {
            text: 'Result',
            align: 'start',
            value: 'result',
            width: 100,
          },
        ],
        headersUpcoming: [
          {
            text: 'Opponent Name',
            align: 'start',
            value: 'opponent.title',
            width: 200,
          },
          {
            text: 'Location',
            align: 'start',
            value: 'location',
            width: 150,
          },
          {
            text: 'Stadium Name',
            align: 'start',
            value: 'stadiumName',
            width: 150,
          },
          {
            text: 'Indicator',
            align: 'start',
            value: 'locationIndicator',
            width: 80,
          },
          {
            text: 'Date',
            align: 'start',
            value: 'date',
            width: 80,
          },
          {
            text: 'Time',
            align: 'start',
            value: 'time',
            width: 80,
          },
        ],
        isLoading: false,
        isFormValid: true,
        isFormDisabled: false,
      };
    },
    computed: {
      ...mapGetters('programs', { program: 'getProgram' }),
    },
    methods: {
      ...mapActions('ui', { showToast: 'showToast' }),
      fetchClient: ClientService.fetchClient,
      fetchScheduleEventInfo: ClientService.fetchScheduleEventInfo,
      fetchOpponentInfo: ClientService.fetchOpponentInfo,
      fetchGlobalSport: ClientService.fetchGlobalSport,
      fetchOpponentScheduleResultEvents: ClientService.fetchOpponentScheduleResultEvents,
      fetchOpponentScheduleUpcomingEvents: ClientService.fetchOpponentScheduleUpcomingEvents,
      fetchPlayers: ClientService.fetchPlayers,
      getOpponentTenantId: ClientService.getOpponentTenantId,
      async importSchedule() {
        let teamInfo = this.program.event.away;
        if (teamInfo.name === '') {
          let pageEvent = this.getPageEvent();
          teamInfo = pageEvent.opponent;
        }

        const globalSport = await this.fetchGlobalSport({
          globalSportId: this.globalSportId,
          tenantId: this.opponentTenantId,
        });
        const sportName = globalSport.shortDisplay;

        const opponentName = teamInfo.name;
        const headerText = `${opponentName} ${sportName} Schedule`;

        this.setPageTitle(`${headerText} Import`);

        const httpRegex = /(http|https)/;
        let selectedResultsMap = this.selectedResults.map((item) => {
          if (item.opponent?.image !== '' && !httpRegex.test(item.opponent?.image)) {
            item.opponent.image = `${this.opponentSiteUrl}${item.opponent?.image}`;
          }
          return {
            date: item.date,
            location: item.location,
            locationIndicator: item.locationIndicator,
            result: `${item.result?.status} ${item.result?.teamScore}-${item.result?.opponentScore}`,
            stadiumName: item.stadiumName ? item.stadiumName : '',
            time: DateTime.fromISO(item.date).toFormat('h:mma'),
            opponentLogo: item.opponent?.image,
            opponent: item.opponent?.title,
          };
        });

        let selectedUpcomingMap = this.selectedUpcoming.map((item) => {
          if (item.opponent?.image !== '' && !httpRegex.test(item.opponent?.image)) {
            item.opponent.image = `${this.opponentSiteUrl}${item.opponent?.image}`;
          }
          return {
            date: item.date,
            location: item.location,
            locationIndicator: item.locationIndicator,
            stadiumName: item.stadiumName ? item.stadiumName : '',
            time: DateTime.fromISO(item.date).toFormat('h:mma'),
            opponentLogo: item.opponent?.image,
            opponent: item.opponent?.title,
          };
        });

        return {
          blocks: [
            {
              id: 1,
              data: {
                text: headerText,
              },
              type: 'header',
              config: {
                label: 'Describe what this page is about',
                title: 'Page Heading',
                placeholder: 'Page Heading Text Goes Here...',
              },
            },
            {
              id: 2,
              data: {
                thirdText: '',
                fourthText: '',
                alt: `${opponentName} team logo`,
                image: teamInfo.logoUrl,
                firstText: teamInfo.mascot,
                secondText: teamInfo.name,
                locationType: 'away',
              },
              type: 'textimagerecord',
              config: {
                label: 'Choose which team this page is associated with',
                title: 'Team Schedule Heading',
                imageLabel: 'School Logo',
                displayConfig: true,
                firstTextDefault: 'mascot',
                secondTextDefault: 'name',
                thirdTextDefault: '',
                fourthTextDefault: 'standing',
                placeholderFirstText: 'School Nickname/Mascot',
                placeholderSecondText: 'School Name',
                placeholderThirdText: 'Conference Record',
                placeholderFourthText: 'Overall Record',
              },
            },
            {
              id: 3,
              data: {
                results: selectedResultsMap,
                upcoming: selectedUpcomingMap,
              },
              type: 'schedule',
              config: {
                title: 'Schedule Panel',
                subtitle: 'Components that build your schedule & results page.',
              },
            },
          ],
        };
      },
      async onSubmit() {
        this.isFormValid = !!this.selectedResults.length || !!this.selectedUpcoming.length;
        if (!this.isFormValid) return;
        try {
          this.toggleLoading();
          this.isFormDisabled = true;
          const content = await this.importSchedule();
          this.setPageContent(content);
          this.goNext();
        } catch (error) {
          const msg = error?.response?.data || 'Could not import schedule data';
          this.showToast(msg);
          // // console.warn(error);
        } finally {
          this.toggleLoading();
          this.isFormDisabled = false;
        }
      },
      reset() {
        this.selectedResults = [];
        this.selectedUpcoming = [];
        this.isFormValid = true;
      },
    },
    async created() {
      try {
        this.isFormDisabled = true;
        this.isLoading = true;

        const { clientId } = this.program;
        const client = await this.fetchClient(clientId);
        const { tenantId } = client;

        this.globalSportId = this.program.sport.globalSportId;
        if (!this.globalSportId) {
          let pageSport = this.getPageSport();
          this.globalSportId = pageSport.globalSportId;
        }

        let scheduleId = this.program.event.scheduleId;
        if (!scheduleId) {
          let pageEvent = this.getPageEvent();
          scheduleId = pageEvent.id;
        }
        const schedule = await this.fetchScheduleEventInfo({ scheduleId, tenantId });
        const {
          opponent: { id },
        } = schedule;

        const opponentInfo = await this.fetchOpponentInfo({ tenantId, id });
        const opponentGlobalId = opponentInfo?.opponentGlobalId;
        const opponentGlobal = await this.getOpponentTenantId(opponentGlobalId.toString());
        this.opponentTenantId = opponentGlobal.client_abbreviation;
        this.opponentSiteUrl = opponentGlobal.website;

        const globalSport = await this.fetchGlobalSport({
          globalSportId: this.globalSportId,
          tenantId: this.opponentTenantId,
        });
        const sportId = globalSport.id;

        this.scheduleResults = await this.fetchOpponentScheduleResultEvents({
          sportId,
          tenantId: this.opponentTenantId,
        });

        this.scheduleUpcoming = await this.fetchOpponentScheduleUpcomingEvents({
          sportId,
          tenantId: this.opponentTenantId,
        });
      } catch (error) {
        this.showToast('Could not fetch roster data');
        // // console.warn(error);
      } finally {
        this.isFormDisabled = false;
        this.isLoading = false;
      }
    },
    watch: {
      selectedResults(selectedResults: ScheduleEventsGetters[]) {
        if (selectedResults && selectedResults.length) this.isFormValid = true;
      },
      selectedUpcoming(selectedResults: ScheduleEventsGetters[]) {
        if (selectedResults && selectedResults.length) this.isFormValid = true;
      },
    },
  });
</script>
